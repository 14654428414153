<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge"
        >Ziffernrechnen – Übergang Diagnose</v-card-title
      >
    </v-card>
    <br />
    <br />
    <v-card outlined>
      <v-card-title class="titleSmaller"
        >Welche Facetten des Hintergrundwissens konnten beim Ziffernrechnen
        ausgemacht werden?
      </v-card-title>
      <v-card-text>
        Das Hintergrundwissen im Bereich Ziffernrechnen lässt sich aufgliedern
        in die drei Facetten:
        <ul>
          <li>Verfahrensweisen</li>
          <li>Verstehensorientierte Erarbeitung</li>
          <li>Flexibles Rechnen</li>
        </ul>
        Das Wissen um diese drei Teilbereiche ist vor allem auf für die Diagnose
        und Förderung der Kinder wichtig, um zum einen diagnostizieren zu
        können, wo genau beispielsweise Probleme auftreten und daran
        anschließend passgenaue Förderung anzustreben.
      </v-card-text>
    </v-card>
    <br />
    <br />
    <v-card outlined>
      <v-card-title class="titleSmaller"
        >Was erwartet Sie im Bereich der Diagnose und Förderung im
        Ziffernrechnen?
      </v-card-title>
      <v-card-text>
        In mehreren Aufgaben betrachten Sie gleich Lösungen von Kindern, um
        <ul>
          <li>Hintergrundwissen und Diagnose zu verknüpfen,</li>
          <li>
            typische Fehler im Bereich des schriftlichen Rechnens
            nachzuvollziehen und nach Gemeinsamkeiten zu ordnen,
          </li>
          <li>
            Aussagen von Lernenden danach zu analysieren, inwiefern diese von
            Verständnis zeugen.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <AppBottomNavZR
      next="/ziffernrechnen/diagnose-und-foerderung"
      back="/ziffernrechnen/hintergrundwissen/flexibles-rechnen"
    />
  </div>
</template>

<script>
import AppBottomNavZR from "@/common/AppBottomNavZR";
export default {
  components: {
    AppBottomNavZR,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
